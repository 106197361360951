<template>
  <div class="home">
    <table-businesspartner></table-businesspartner>
  </div>
</template>

<script>
import tableBusinesspartner from '@/components/pages/maint/businesspartner/tableBusinesspartner.vue';

export default {
	components: {
		'table-businesspartner': tableBusinesspartner,
	}
};
</script>

<style>

</style>
