<template>
    <v-dialog v-model="dialog" persistent max-width="70%" scrollable>
      <template v-slot:activator="{ on }">
        <app-add-button v-bind:module="'businesspartner'"></app-add-button>
      </template>
         <v-form
            ref="form"
            v-model="isvalid"
            lazy-validation
          >
        <v-card tile>
          <v-toolbar
            flat
            dark
            color="primary"
          >
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
        <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex>
                  <span class="title blue--text text-darken-2">Business Partner Information</span>
                </v-flex>
              </v-layout>
            <v-layout wrap>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.name"
                    :counter="200"
                    :rules="defaultRules"
                    label="BP Name"
                    required
                    maxlength=200
                ></v-text-field>
                </v-flex>
                 <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.bp_code"
                    label="BP Code"
                    :rules="defaultRules"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.class_code"
                    label="Class Code"
                    :rules="defaultRules"
                ></v-text-field>
                </v-flex>
                <!-- <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.payee_code"
                    label="Payee Code"
                ></v-text-field>
                </v-flex> -->
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.brnc_code"
                    label="Branch Code"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field 
                    v-model="editedItem.cctr_code"
                    :counter="5"
                    label="Default CCTR Code"
                    required
                    maxlength=5
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-select
                    v-model="editedItem.status"
                    :items="activeStatus"
                    :rules="[v => !!v || 'Please select Status']"
                    label="Status"
                    data-vv-name="activeStatus"
                    required
                ></v-select>
                </v-flex>
                <!-- <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.batch_no"
                    label="Batch No"
                ></v-text-field>
                </v-flex> -->
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.pin"
                    label="PIN"
                ></v-text-field>
                </v-flex>
                <!-- <v-flex xs12 sm6 md4> -->
                <!-- <v-text-field
                    v-model="editedItem.verified"
                    label="Verified"
                ></v-text-field> -->
                <!-- </v-flex> -->
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.president"
                    label="President"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.gen_manager"
                    label="Gen Manager"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.wu_person"
                    label="WU Person"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.wu_mobile"
                    label="WU Mobile"
                ></v-text-field>
                </v-flex>
                <!-- <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.vat_reg"
                    label="Vatable"
                ></v-text-field>
                </v-flex> -->
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.bp_tin"
                    label="BP Tin"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.risk_code"
                    label="Risk Code"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.risk_eval_date"
                    label="Risk Eval Date"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.client_type"
                    label="Client Type"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.dpa_consent_tag"
                    label="DPA Consent Tag"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.pep_tag"
                    label="Pep Tag"
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.kyc_stat"
                    label="KYC Stat"
                ></v-text-field>
                </v-flex>
            </v-layout>
            <v-layout wrap>
                <v-flex>
                  <span class="title blue--text text-darken-2">Address Information</span>
                </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex>
                <v-checkbox
                  v-model="int_address"
                  label="International Address"
                  false-value="0"
                  true-value="1"
                ></v-checkbox>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="editedItem.house_unit"
                  :counter="30"
                  label="House Unit"
                  :rules="defaultRules"
                  required
                  maxlength="30"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="editedItem.street"
                  :rules="defaultRules"
                  :counter="50"
                  label="Street"
                  required
                  maxlength="50"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-autocomplete
                  v-if="brgyData.length !== 0 && int_address === '0'"
                  v-model="editedItem.barangay"
                  :items="brgyData"
                  label="Barangay"
                  item-text="brpcb_id_name"
                  item-value="brpcb_id_name"
                  @change="getBrgyDetails()"
                  :rules="defaultRules"
                  required
                >
                </v-autocomplete>
                <v-text-field
                  v-model="editedItem.barangay"
                  :rules="defaultRules"
                  :counter="50"
                  label="Barangay"
                  required
                  v-else
                  maxlength="50"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-autocomplete
                  v-if="int_address === '0'"
                  v-model="editedItem.municipality"
                  :items="municipalityData"
                  label="Municipality/City"
                  item-text="name"
                  item-value="name"
                  @change="getDetails()"
                  :rules="defaultRules"
                  required
                >
                </v-autocomplete>
                <v-text-field
                  v-else
                  v-model="editedItem.municipality"
                  :rules="defaultRules"
                  :counter="50"
                  label="Municipality/City"
                  required
                  maxlength="50"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-autocomplete
                  v-if="int_address === '0'"
                  v-model="editedItem.region"
                  :items="regionData"
                  label="Region"
                  item-text="name"
                  item-value="name"
                  @change="getRegionDetails()"
                  :rules="defaultRules"
                  required>
                </v-autocomplete>
                <v-text-field
                  v-model="editedItem.region"
                  :rules="defaultRules"
                  :counter="50"
                  label="Region"
                  v-else
                  required
                  maxlength="50">
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field
                  v-model="editedItem.zip_code"
                  :rules="defaultRules"
                  :counter="4"
                  label="Zip Code"
                  required
                  :readonly="int_address=='0'"
                  maxlength="4"
                >
                  <!-- <template v-slot:append v-if=" int_address === '0'">        
                      <v-icon @click="getAddress(editedItem.zip_code, 'zipcode')" :color="hrisToken ? 'primary' : 'gray'" :disabled="!hrisToken"> mdi-magnify </v-icon> 
                    </template> -->
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.country_id"
                    label="Country ID"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.contact_person"
                    :rules="defaultRules"
                    label="Contact Person"
                    maxlength=50
                    required
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap>
                <v-flex>
                  <span class="title blue--text text-darken-2">Contact Details</span>
                </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 sm6 md4>
                <v-text-field 
                  v-model="editedItem.contact_num"
                  :counter="15"
                  label="Contact No."
                  :rules="defaultRules"
                  required
                  maxlength=15
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field 
                  v-model="editedItem.fax"
                  :counter="12"
                  label="Fax"
                  required
                  maxlength=12
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-select
                    v-model="editedItem.hold"
                    :items="activeStatus"
                    label="Hold"
                    data-vv-name="activeStatus"
                    required
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field 
                  v-model="editedItem.email_address"
                  :counter="50"
                  label="Email Address"
                  required
                  maxlength=50
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field 
                  v-model="editedItem.tin"
                  :counter="15"
                  label="TIN"
                  :rules="defaultRules"
                  required
                  maxlength=15
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-select
                  v-model="editedItem.business_type"
                  :items="businessType"
                  :rules="[v => !!v || 'Please select Business Type']"
                  label="Business Type"
                  data-vv-name="businessType"
                  required
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-select
                  v-model="editedItem.vat"
                  :items="vatType"
                  :rules="[v => !!v || 'Please select VAT Type']"
                  label="VAT Type"
                  data-vv-name="vatType"
                  required
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md4 v-if="editedItem.vat !== '0'">
                <v-select
                  v-model="editedItem.atc_code"
                  :items="dataAtc"
                  data-vv-name="dataAtc"
                  :rules="[v => !!v || 'Please select Alpha Tax Code']"
                  item-value="atc_code"
                  label="Alpha Tax Code"
                  required
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.atc_code }} - {{ data.item.atc_desc }} ( {{ data.item.atc_rate }}% )
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.atc_code }} - {{ data.item.atc_desc }} ( {{ data.item.atc_rate }}% )
                  </template>
                </v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex md12>
                <span class="title blue--text text-darken-2">Payee Banks</span> 
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex md12>
                <add-payee-bank :details.sync="editedItem.payee_banks" :deleted.sync="deleted" :banks.sync="data"></add-payee-bank>
              </v-flex>
            </v-layout>
            </v-container>
        </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" dark @click="save">Save</v-btn>
              <v-btn color="red darken-1" text @click="close">Cancel</v-btn>
            </v-card-actions>
          </v-card>
          </v-form>
    
          <v-snackbar
            v-model="snackbar"
            right
          >
            {{ error_message }}
            <v-btn
              color="pink"
              text
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </v-snackbar>
        </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';
import AddPayeeBanks from '../payeebank/addPayeeBank.vue';

export default {
	components: {
		'app-add-button': AppAddButtonVue,
		'add-payee-bank': AddPayeeBanks
	},
	watch: {
		banks: {
			handler(){
				this.getData();
			}
		},
		alphataxcodes: {
			handler() {
				this.getDataAlphataxcodes();
			}
		},
	},
	data: () => ({
		data:[],
		nextPage: '',
		dataAtc: [],
		nextPageAtc: '',
		snackbar: false,
		error_message: '',
		hasError: false,
		payeeBank:[],
		deleted: [],
		int_address: '0',
		isvalid: false,
		brnc_codeRules: [
			v => !!v || 'Branch Code is required',
			v => (v && v.length <= 3) || 'Code must be 3 characters or less.',
		],
		brnc_nameRules: [
			v => !!v || 'Branch Name is required',
			v => (v && v.length <= 50) || 'Branch Name must be less than 50 characters',
		],
		regn_codeRules: [
			v => !!v || 'Region Code is required',
			v => (v && v.length <= 3) || 'Branch Name must be 3 characters or less',
		],
		zip_codeRules: [
			v => !!v || 'Zip Code is required',
			v => (v && v.length <= 4) || 'Zip Code must be 4 characters or less',
		],
		brnc_abbrRules: [
			v => !!v || 'Branch Abbreviation is required',
			v => (v && v.length <= 4) || 'Branch Abbreviation must be 3 characters or less',
		],
		brnc_addrRules: [
			v => !!v || 'Branch Address is required',
			v => (v && v.length <= 200) || 'Branch Address must be 200 characters or less',
		],
		defaultRules: [
			v => !!v || 'Required',
		],
		brgyData : [],
		municipalityData: [],
		regionData: [],
	}),

	computed: {
		...mapGetters({
			businesspartners: 'businesspartner/businesspartners',
			activeStatus: 'businesspartner/activeStatus',
			editedItem: 'businesspartner/editedItem',
			defaultItem: 'businesspartner/defaultItem',
			dialog: 'businesspartner/dialog',
			editedIndex: 'businesspartner/editedIndex',
			formTitle: 'businesspartner/formTitle',
			valid: 'businesspartner/valid',
			currUser: 'auth/currUser',
			businessType: 'payefile/businessType',
			vatType: 'payefile/vatType',
			banks: 'bankheader/bankheaders',
			alphataxcodes: 'alphataxcode/alphataxcodes',
			hrisToken: 'auth/hrisToken'
		}),
	}, 
	mounted () {
		this.$store.dispatch('bankheader/getBankheaders');
		this.$store.dispatch('alphataxcode/getAlphataxcodeDropdown');
		this.brgyData = [];
		this.municipalityData = [];
		this.regionData = [];
		this.getMunicipality();
		this.getRegion();
	},
	methods: {
		async getData(){
			this.data = this.banks.data;
			this.nextPage = this.banks.next_page_url;
			let page = 2;

			while (this.nextPage !== null && page !== this.banks.current_page){
				await this.$store.dispatch('bankheader/getData', page).then(response => {
					this.nextPage = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.data.push(details);
					});
				});
				page ++;
			}
		},
		newEntry(){
			this.$store.dispatch('businesspartner/setDialog',true);
			this.$store.dispatch('businesspartner/setformTitle','New Entry');
			this.$store.dispatch('businesspartner/setValid',false);
		},
		close () {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('businesspartner/setValid',true);
				this.$store.dispatch('businesspartner/setDialog',false);
				this.$store.dispatch('businesspartner/setEditedIndex','-1');
				this.isvalid = false;
			}, 2000);
		},
		async getDataAlphataxcodes(){
			this.dataAtc = this.alphataxcodes.data;
			this.nextPageAtc= this.alphataxcodes.next_page_url;
			let page = 2;

			while (this.nextPageAtc !== null){
				await this.$store.dispatch('alphataxcode/getData', page).then(response => {
					this.nextPageAtc = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataAtc.push(details);
					});
				});
				page++;
			}
		},
		save () { 
			this.hasError = false;
			this.$store.dispatch('businesspartner/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			this.editedItem.city = this.editedItem.municipality;
			if(this.valid){
				let updated = [];
				let payee_bank = [];
				if(this.editedItem.payee_banks){
					this.editedItem.payee_banks.map(details => {
						if(!details.currency || !details.account_number || !details.bank_id || !details.bank_branch){
							this.hasError = true;
						}
					});
				}
				this.editedItem.payee_banks.map(details => {
					details.edit_by = this.currUser.id;
					details.bank_address = details.bank_branch;
					details.status = 1;
					if(details.payee_account_id != ''){
						updated.push(details);
					}else{
						details.payee_id = this.editedItem.payee_id;
						details.payee_account_id = this.editedItem.id;
						payee_bank.push(details);
					}
				});
				delete this.editedItem['updated_at'];
				delete this.editedItem['created_at'];
				this.editedItem.updated = updated;
				this.editedItem.deleted = this.deleted;
				this.editedItem.payee_banks = payee_bank;
				this.editedItem.payee_type = '2';
				if(!this.hasError){
					if (this.editedIndex > -1) {
						this.$store.dispatch('businesspartner/updateBusinesspartner',this.editedItem);
					} else {
						this.$store.dispatch('businesspartner/saveBusinesspartner',this.editedItem);
					}
					setTimeout(() => {
						this.close();
					}, 3000);
				} else {
					this.snackbar = true;
					this.error_message = 'Please complete the details for payee bank.';
				}
			}
		},
		getMunicipality() {
			let payload = {
				url: 'backend.region-province-city-list',
				data: ''
			};
			this.$store
				.dispatch('payefile/getAddress', {
					data: payload,
					token: this.hrisToken,
				})
				.then((response) => {
					this.municipalityData = response.data.body;
				});
		},
		getRegion() {
			let payload = {
				url: 'backend.region-list',
				data: ''
			};
			this.$store
				.dispatch('payefile/getAddress', {
					data: payload,
					token: this.hrisToken,
				})
				.then((response) => {
					this.regionData = response.data.body;
				});
		},
		getDetails() {
			this.editedItem.barangay = '';
			this.editedItem.zip_code = '';
			let payload = {
				url: 'backend.region-province-city-by-province',
				data: this.editedItem.municipality
			};
      
			this.$store
				.dispatch('payefile/getAddress', {
					data: payload,
					token: this.hrisToken,
				})
				.then((response) => {
					this.brgyData = [];
					let data = response.data.body;
					if(data.length > 1){
						this.brgyData = data;
					} else {
						if (data[0].zipcode) {
							this.editedItem.zip_code = data[0].zipcode;
							this.zipcode = true;
						}
						if (data[0].br_id_name) {
							this.editedItem.region = data[0].br_id_name;
						}
					}
				});
		},
		getRegionDetails() {
			this.editedItem.barangay = '';
			this.editedItem.zip_code = '';
			this.editedItem.municipality = '';
			let payload = {
				url: 'backend.region-by-name',
				data: this.editedItem.region
			};
			this.$store
				.dispatch('payefile/getAddress', {
					data: payload,
					token: this.hrisToken,
				})
				.then((response) => {
					this.municipalityData = [];
					response.data.body.map(det => {
						let data = {name: det.brpc_id_name};
						this.municipalityData.push(data);
					});
				});
		},
		getBrgyDetails(){
			let data = this.brgyData.filter(e => e.brpcb_id_name == this.editedItem.barangay); 
			if (data[0].zipcode) {
				this.editedItem.zip_code = data[0].zipcode;
				this.zipcode = true;
			}
		}
	},
};
</script>